import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { BlogLink, ExternalLink } from 'components/Links';
import { Container, HeroContainer, Section } from 'components/Container';
import { List } from 'components/List';
import { HrefButton } from 'components/Button';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const Scholarship: FC<LocalizedPageProps> = () => {
  const {
    translations: { freeUsers },
  } = useContentfulLocale();

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>Sharesight Fintech Scholarship</h1>
          <p>
            Get AUD $2000 to use towards your studies and kick-start your progress towards a
            rewarding career in finance.
          </p>

          <br />

          <HrefButton href="#application" appearance="primary">
            Learn more
          </HrefButton>
        </Section>
      </HeroContainer>

      <Container>
        <Section>
          <p>
            As a financial technology services company, Sharesight as a business is passionate about
            helping students get started on their pathway to successful careers in the financial and
            financial technology spaces.
          </p>
          <p>
            Our business walks the line between investing, technology and personal finance,
            combining elements of each in order to help retail investors lead easier, wealthier
            lives. We are also a young and forward-thinking company who is active in encouraging
            participation in the burgeoning Australian fintech sector.
          </p>
          <p>
            As a result, we are seeking to financially assist students who have an interest in
            working within the finance sector as a career, whether it be banking, stockbroking,
            financial consultation, accounting, tax, or any variation of the above.
          </p>
          <p>
            We believe gaining a solid education and knowledge of business fundamentals is crucial
            not only in helping students lead successful professional lives, but also in adopting an
            investing mindset from a young age that will help them grow their wealth over the long
            term.
          </p>
        </Section>
      </Container>
      <Container>
        <Section>
          <h2>About the scholarship</h2>
          <p>
            We are offering this scholarship to prospective students currently aiming to study at
            Australian universities within the greater financial field. These include Bachelor’s
            degrees in the following disciplines:
          </p>
          <List
            items={[
              { id: 'accounting', content: 'Accounting' },
              { id: 'finance', content: 'Finance' },
              { id: 'finance-planning', content: 'Finance Planning' },
              { id: 'financial-technologies', content: 'Financial Technologies' },
              { id: 'tax', content: 'Tax' },
            ]}
          />
          <p>
            We are pleased to offer a one-off cash grant in 2025 of AUD $2,000 to support the
            studies of a successful applicant.
          </p>
          <p>
            <BlogLink to="sharesight-fintech-scholarship-2023-winner">
              Read about past winners
            </BlogLink>{' '}
            on our blog.
          </p>
        </Section>
      </Container>
      <Container>
        <Section>
          <h2>About Sharesight</h2>
          <p>
            Sharesight is an award-winning fintech company with offices in Wellington, New Zealand
            and Sydney, Australia.
          </p>
          <p>
            Designed to make it easy for DIY investors to track their performance, Sharesight’s
            secure, online portfolio tracker and performance reporting tool is used by over{' '}
            {freeUsers} DIY investors and finance professionals in more than 150 countries.
          </p>
          <p>
            Sharesight syncs with leading stockbrokers to track trades, dividends and corporate
            actions automatically, while also providing a powerful suite of performance and tax
            reporting tools for investors and financial professionals.
          </p>
        </Section>
      </Container>
      <Container id="application">
        <Section>
          <h2>Application & eligibility</h2>
          <p>
            The deadline to apply for the scholarship is <strong>Dec 31, 2024</strong>.
          </p>
          <p>As an applicant you must:</p>
          <List
            items={[
              {
                id: 'be-a-student',
                content: 'Be a student studying at an Australian university; and',
              },
              {
                id: 'enrolment-evidence',
                content: 'Be able to provide evidence of enrolment or intent to enroll; and',
              },
              {
                id: 'social-media',
                content:
                  'Agree to your name and photo potentially being used on our social media platforms for the purpose of promoting our scholarship recipients.',
              },
            ]}
          />
          <p>The winner will be chosen in Jan 2025 and the grant paid prior to Feb 1, 2025.</p>
          <ExternalLink to="https://forms.gle/QW7wJgEsRntR8ukk8" asButton appearance="primary">
            Apply now
          </ExternalLink>
        </Section>
      </Container>
    </Layout>
  );
};

export default Scholarship;
